import React, { createContext, useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { InsMonitorModel } from '@/types/home';
import { useTranslation } from 'next-i18next';
import { accountDelete, fetchCouponInfo } from '@/lib/service';
import { CouponInfoModel } from '@/types/coupon';
import { HomeUpdateEmailKey } from '@/shared/app-common';

type UserProviderType = {

  openLogin?: boolean;
  isLoginView?: boolean;
  openLoginResult?: boolean;
  accountList?: InsMonitorModel[];

  onOpenLoginModal?: (open: boolean) => void;
  onChangedLoginView?: (isLoginView: boolean) => void;
  onChangedLoginResult?: (isLoginView: boolean) => void;
  onAddToken?: (token?: string, signInMethod?: string, idToken?: string) => void;
  onInitAccountList?: (accountList?: InsMonitorModel[]) => void;
  onAddAccount?: (account?: InsMonitorModel) => void;
  onSignOut?: (showAlert?: boolean) => void;
  onDeleteUser?: () => void;

  isLogin?: boolean;

  coupon?: CouponInfoModel;
  onRefreshCouponData?: (coupon?: CouponInfoModel) => void;

  isLoginLoading?: boolean;
  onRefreshLoginLoading?: (isLoginLoading?: boolean) => void;
};

const Context = createContext<UserProviderType>({});

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  const router = useRouter();
  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [isLoginView, setIsLoginView] = useState<boolean>(false);
  const [openLoginResult, setOpenLoginResult] = useState<boolean>(false);
  const [accountList, setAccountList] = useState([]);
  const [coupon, setCoupon] = useState<CouponInfoModel>(null);
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);
  const token = Cookies.get('userToken');
  const [isLogin, setIsLogin] = useState<boolean>(
    token != null && token != undefined && token.length > 0,
  );

  useEffect(() => {
    const token = Cookies.get('userToken');
    setIsLogin(token != null && token != undefined && token.length > 0);
  }, []);

  const loadCouponData = () => {
    fetchCouponInfo().then((res) => {
      setCoupon(res?.data)
    }).catch((err) => {
      setCoupon(null)
    })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLogin) {
        loadCouponData();
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLogin) {
        loadCouponData();
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [isLogin]);

  const onOpenLoginModal = (open: boolean) => {
    setOpenLogin(open);
  };

  const onChangedLoginView = (open: boolean) => {
    setIsLoginView(open);
  };

  const onChangedLoginResult = (open: boolean) => {
    setOpenLoginResult(open);
  };

  const onAddToken = (token?: string, signInMethod?: string, idToken?: string) => {
    if (token != undefined) {
      Cookies.set('userToken', token, { expires: 7 });
      Cookies.set('SignInMethod', signInMethod, { expires: 7 });
      Cookies.set('IdToken', idToken, { expires: 7 });
    } else {
      Cookies.remove('userToken');
      Cookies.remove('SignInMethod');
      Cookies.remove('IdToken');
    }
    setIsLogin(token != undefined);
  };

  const onAddAccount = (account?: InsMonitorModel) => {
    if (account != undefined) {
      setAccountList([...accountList, account]);
    }
  };

  const onInitAccountList = (accountList?: InsMonitorModel[]) => {
    setAccountList([...(accountList ?? [])]);
  };
  const clearAllCookies = () => {
    const cookies = document.cookie.split(';');
    // 遍历并删除每个cookie
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      Cookies.remove(name);
    }
  };

  const onSignOut = (showAlert?: boolean) => {
    clearAllCookies();
    if (showAlert) {
      message.success(t('SignOutSuccessful'));
    }
    onAddToken(undefined);
    setCoupon(null)

    if (typeof window !== 'undefined') {
      localStorage.removeItem(HomeUpdateEmailKey)
    }
  };

  const onDeleteUser = () => {
    accountDelete()
      .then((res) => {
        if (res.code === 0) {
          onSignOut(false);
          setAccountList([]);
          setCoupon(null)
          message.success(t('UserDeleted'));
          router.replace('/');
        } else {
          message.error(res?.message);
        }
      })
      .catch((error) => {
        message.error(error?.message);
        onChangedLoginView(true);
        onOpenLoginModal(true);
      });
  };
  const onRefreshCouponData = (coupon?: CouponInfoModel) => {
    setCoupon(coupon)
  }

  const onRefreshLoginLoading = (isLoginLoading?: boolean) => {
    setIsLoginLoading(isLoginLoading)
  }

  const exposed = {
    openLogin,
    isLoginView,
    openLoginResult,
    isLogin,
    accountList,
    onOpenLoginModal,
    onChangedLoginView,
    onChangedLoginResult,
    onAddToken,
    onInitAccountList,
    onAddAccount,
    onSignOut,
    onDeleteUser,
    coupon,
    onRefreshCouponData,
    isLoginLoading,
    onRefreshLoginLoading,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useUserProvider = () => useContext(Context);

export default UserProvider;
