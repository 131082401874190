import axios from '@/shared/axios';
import { CouponInfoModel } from '@/types/coupon';
import { BaseResponse } from '@/types/web.interface';
import {
  MediaUserItem,
  UnfollowRecordModel,
  FollowStatusModel,
  RecordTaskModel,
  UnfollowTrackingModel,
  FollowBackModel,
  HistoryOrderModel,
} from '@/types/unFollow';
export async function fetchCouponInfo() {
  const res = await axios.get<BaseResponse<CouponInfoModel>>(`/ins/monitor/item/coupon/info`);
  return res.data;
}

// unfollow
export async function fetchUnfollowData(searchName?: any) {
  const res = await axios.get<BaseResponse<MediaUserItem>>('/unfollow/search', {
    params: {
      searchName,
    },
  });
  return res.data;
}

export async function fetchRecordList() {
  const res = await axios.get<BaseResponse<[UnfollowRecordModel]>>('/unfollow/record');
  return res.data;
}
export async function fetchRecordDetail(recordId?: any) {
  const res = await axios.get<BaseResponse<UnfollowRecordModel>>(
    `/unfollow/record/detail/${recordId}`,
  );
  return res.data;
}

export async function fetchFollowBackDetail(recordId?: any, params?: any) {
  const res = await axios.get<BaseResponse<[FollowStatusModel]>>(
    `/unfollow/record/follow_back/${recordId}`,
    {
      params,
    },
  );
  return res.data;
}

export async function fetchRecordTasksList(recordId?: any) {
  const res = await axios.get<BaseResponse<[RecordTaskModel]>>(
    `/unfollow/record/tasks/${recordId}`,
  );
  return res.data;
}

export async function fetchTaskDetailList(taskId?: any, params?: any) {
  const res = await axios.get<BaseResponse<[FollowStatusModel]>>(
    `/unfollow/record/task_data/${taskId}`,
    {
      params,
    },
  );
  return res.data;
}

export async function recordConnect(token: any, list: any) {
  const res = await axios.post<BaseResponse<any>>(`/unfollow/record/connect`, list, {
    headers: {
      Authorization: token,
    },
  });
  return res.data;
}

export async function fetchUnfollowTrackingList() {
  const res = await axios.get<BaseResponse<[UnfollowTrackingModel]>>(
    '/unfollow/list/unfollow_tracking',
  );
  return res.data;
}

export async function fetchFollowBackList() {
  const res = await axios.get<BaseResponse<[FollowBackModel]>>('/unfollow/list/follow_back');
  return res.data;
}
export async function fetchUnfollowListOrder() {
  const res = await axios.get<BaseResponse<[HistoryOrderModel]>>('/unfollow/list/order');
  return res.data;
}

export async function orderAddAccount(media_name: string, order_id: number) {
  const res = await axios.post<BaseResponse<any>>('/unfollow/order/add_user', {
    media_name,
    order_id,
  });
  return res.data;
}
